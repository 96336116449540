@font-face {
    font-family: "Roboto-Regular";
    src: url(../fonts/Roboto/Roboto-Regular.ttf) format("truetype");
    font-weight: 400;
    font-style: normal
}

@font-face {
    font-family: "Roboto-Medium";
    src: url(../fonts/Roboto/Roboto-Medium.ttf) format("truetype");
    font-weight: 500;
    font-style: normal
}

@font-face {
    font-family: "Roboto-Bold";
    src: url(../fonts/Roboto/Roboto-Bold.ttf) format("truetype");
    font-weight: 500;
    font-style: normal
}

@font-face {
    font-family: "RoobertRegular";
    src: url(../fonts/Roobert/RoobertRegular.ttf) format("truetype");
    font-style: normal;
    font-weight: 900;
}

@font-face {
    font-family: "RoobertBold";
    src: url(../fonts/Roobert/RoobertBold.ttf) format("truetype");
    font-style: normal;
    font-weight: bolder;
}


.ReactModal__Content {
    font-weight: 400;
    inset: unset !important;
    padding: 30px !important;
    max-width: 402px;
    max-height: 100%;
    box-shadow: 0 1px 7px 0 rgba(76, 113, 252, .28);
    background-color: hsla(0, 0%, 100%, .98) !important;
    display: block;
    min-width: 250px;
    min-height: 180px;
    position: fixed !important;
    z-index: 100;
    left: 50% !important;
    top: calc(50% - 30px) !important;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    border-radius: 5px !important;
    opacity: 1;
    border: none !important;
}


.p {
    font-weight: 400
}

.p,
.paragraph-a,
.paragraph-b {
    font-family: "Roboto-Regular"
}

.paragraph-a,
.paragraph-b {
    font-size: 14px
}

.paragraph-a,
.paragraph-b {
    color: #4a4a4a
}

.h1--a {
    color: #6d7278;
    font-family: "Roboto-Medium";
    font-size: 20px;
}

.h3--type-a {
    color: #4a4a4a;
    font-family: "Roboto-Bold";
    font-size: 16px;
}

.otp-description {
    margin-top: 10px;
    text-align: left;
    position: relative;
    overflow: hidden;
    min-height: 100px;
    line-height: 21px;
}

.otp-description span {
    font-family: "Roboto-Regular";
    margin-bottom: 20px;
    display: block;
    color: #4a4a4a;
    font-size: 14px;
}

div.containerOTP {
    height: 90px;
    text-align: center;
}

div.containerOTP div:first-child {
    width: fit-content;
    margin: auto;
}

.otp-input,
.otp-input-status-invalid {
    border: 0;
    outline: 0;
    color: #4a4a4a;
    margin-right: 16px;
    text-align: center;
    font-size: 36px;
    font-family: "Roboto-Medium";
    font-weight: 500;
    cursor: pointer;
    will-change: border;
    transition: border .3s ease-in-out;
    -webkit-appearance: none;
    -moz-appearance: textfield;
    width: 32px;
    border-radius: 0;
    padding-left: 0;
    padding-right: 0
}

.otp-input {
    border-bottom: 3px solid #4c71fc;
    margin: 8px 8px;
}

.otp-input-status-invalid {
    border-bottom: 3px solid #d0161f
}

div.label-otp span {
    color: #6d7278;
    font-size: 11px;
    font-family: "Roboto-Regular";
    font-weight: 400;
}

.otp-container {
    max-width: 342px;
    margin-top: 20px;
}

.otp-ip-address {
    margin-bottom: 20px;
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
}

.otp-ip-address .paragraph-a {
    line-height: 21px;
    text-align: right;
}

.otp-trusted-device span {
    padding: 0 0 0 10px;
    height: auto;
}

.otp-trusted-device .paragraph-i {
    color: #4c71fc;
    font-size: 12px;
    font-family: "Roboto-Regular";
}

.otp-trusted-device {
    margin-top: 5px;
    margin-bottom: 17px;
    display: flex;
    align-items: center;
}

.otp-trusted-device .checkbox {
    position: relative;
    display: inline-block;
    width: 20px;
    height: 20px;
    border: 2px solid #4c71fc;
    border-radius: 5px;
    cursor: pointer;
}

.otp-trusted-device .checkbox input {
    margin: 0;
    padding: 0;
    -webkit-appearance: none;
    cursor: pointer;
    background: #fff;
    outline: none;
    border-radius: 5px;
}

.slider-uncheck:hover:before,
.uncheck:hover {
    background-color: #6d7278
}

.uncheck:hover {
    opacity: .2
}

.slider,
.span-check,
.span-check:before {
    position: absolute;
    cursor: pointer;
    top: 2px;
    left: 2px;
    right: 2px;
    bottom: 2px;
    background-color: #fff;
    transition: all .1s;
    border-radius: 3px
}

.span-check:before,
.slider:before {
    position: absolute;
    content: "";
    background-color: #fff;
    transition: all .1s
}

.span-check {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0
}

.disabled {
    opacity: .4
}

input:checked+.slider,
input:checked+.span-check,
input:checked+.span-check:before {
    background-color: #4c71fc
}

input:checked+.span-check:before {
    left: 5px;
    top: 1px;
    width: 6px;
    height: 11px;
    border: solid #fff;
    border-width: 0 2px 2px 0;
    border-radius: 1px;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    background-color: transparent
}

.loader {
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: hsla(0, 0%, 100%, .8);
    margin: 0 auto;
    z-index: 999
}

.loader,
.loader .frame {
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%)
}

.loader .frame {
    margin: 0;
    position: absolute
}

.loader .text {
    color: #4c71fc;
    text-align: center;
    margin-top: 10px
}

.loader .frame div:first-child {
    text-align: center
}

.loader .frame div:first-child>div {
    width: 33px;
    height: 33px;
    background-color: #4c71fc;
    border-radius: 100%;
    display: inline-block;
    -webkit-animation: sk-bouncedelay 1.4s ease-in-out infinite both
}

.loader .frame div:first-child .one {
    -webkit-animation-delay: -.32s;
    animation-delay: -.32s !important
}

.loader .frame div:first-child .two {
    -webkit-animation-delay: -.16s;
    animation-delay: -.16s !important
}

@-webkit-keyframes sk-bouncedelay {

    0%,
    80%,
    to {
        -webkit-transform: scale(0)
    }

    40% {
        -webkit-transform: scale(1)
    }
}

@keyframes sk-bouncedelay {

    0%,
    80%,
    to {
        -webkit-transform: scale(0);
        transform: scale(0)
    }

    40% {
        -webkit-transform: scale(1);
        transform: scale(1)
    }
}



[type=button],
[type=reset],
[type=submit],
button {
    -webkit-appearance: button;
}

.button--type-danger,
.button--type-line-a,
.button--type-line-b,
.button--type-line-b-multi,
.button--type-line-b-multi-triple,
.button--type-line-c,
.button--type-panel,
.button--type-primary-a,
.button--type-primary-a-multi,
.button--type-primary-c,
.button--type-secondary-a,
.button--type-secondary-c {
    border-radius: 5px;
}

.button {
    border: 1px solid transparent;
    cursor: pointer;
    height: 40px;
    padding: 10px 15px;
    text-transform: none;
}

.button--type-primary-a,
.button--type-primary-b {
    min-width: 100px;
}

.button--type-primary-a {
    background: #4c71fc;
}

.button--type-primary-a {
    border-color: #4c71fc;
}

.otp-buttons {
    padding-top: 0px;
}

.otp-buttons .button--type-primary-a {
    margin-right: 20px;
}

.button--type-link {
    min-width: 100px;
    border-radius: 20px;
    background: transparent;
}

.button:disabled {
    cursor: default;
    opacity: .4;
    pointer-events: none;
}

.paragraph-j {
    font-size: 12px;
    font-family: "Roboto-Regular";
    color: #fff;
}

.paragraph-l {
    font-size: 12px;
    text-decoration: underline;
    font-family: "Roboto-Regular";
    color: #4c71fc;
}

div.footerBar {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    width: -moz-available;
    width: -webkit-fill-available;
    width: stretch;
    background-color: white;
    padding: 15px 40px;
    color: #898C90;
    z-index: 10;
    max-height: 35px;
}

div.footerBar p {
    font-size: 12px;
    margin: 0px;
    padding: 0px;
    font-family: 'Roboto-Regular';
    line-height: 15px;
}

.footerBar a {
    font-size: 12px;
    float: right;
    right: 50px;
    text-decoration: underline;
    height: fit-content;
    padding: 6px 0px;
    color: #898C90;
    background: none;
    border: none;
    cursor: pointer;
}

@media screen and (max-height: 570px) {
    .ReactModal__Overlay {
        position: absolute !important;
        height: 600px;
    }

    .ReactModal__Content {
        position: relative !important;
    }
}

@media screen and (max-width: 449px) {
    div.footerBar {
      padding: 15px 10px;
    }
}