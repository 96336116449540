.modal__overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 50;
    background-color: rgba(236, 240, 255, 0.7);
    opacity: 0.7;
  }
  .modal__view {
    display: block;
    min-width: 250px;
    max-width: 100%;
    min-height: 180px;
    max-height: 100%;
    position: fixed;
    z-index: 100;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    box-shadow: 0 0 10px 0 rgba(70, 110, 255, 0.3);
    background-color: #fff;
    border-radius: 5px;
    opacity: 1;
  }
  
  @keyframes fadeInOpacityModal {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  
  
  .modal--fade-in {
    opacity: 1;
    -webkit-animation-name: fadeInOpacityModal;
    animation-name: fadeInOpacityModal;
    -webkit-animation-iteration-count: 1;
    animation-iteration-count: 1;
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
    -webkit-animation-duration: 0.3s;
    animation-duration: 0.3s;
  }
  
  .modal__content {
    padding: 30px 30px 40px;
    width: 394px;
  }
  
  .alert-modal,
  .alert-modal--type-error,
  .alert-modal--type-info,
  .alert-modal--type-info-centered {
    box-sizing: border-box;
    max-width: 350px;
  }
  
  .alert-modal--type-error path {
    fill: #d0161f;
  }
  
  .alert-modal__header {
    display: table;
  }
  
  .alert-modal__icon {
    display: table-cell;
    padding-right: 10px;
    /*vertical-align: middle;*/
  }
  
  .alert-modal__text {
    margin-top: 15px;
    margin-bottom: 15px;
    text-align: justify;
    line-height: 21px;
  }
  
  .alert-modal__title {
    display: table-cell;
    /*vertical-align: middle;*/
  }
  
  .alert-modal__footer {
    display: flex;
    position: relative;
  }
  
  .alert-modal__footer path {
    fill: #ffffff;
  }
  
  .alert-modal__footer .button {
    min-width: 150px;
  }
  
  .alert-message .alert-message__messages span {
    display: block;
  }
  
  .alert-message {
    display: inline-block;
    position: relative;
    max-width: 350px;
  }
  
  .alert-message .alert-message__messages {
    margin-top: 10px;
    text-align: justify;
    position: relative;
    overflow: hidden;
    line-height: 21px;
    margin-bottom: 10px;
  }
  
  .alert-message .alert-message__checkbox {
    display: -webkit-flex;
    display: flex;
    margin-bottom: 20px;
    -webkit-align-self: center;
    align-self: center;
  }
  
  .alert-message .alert-message___check,
  .alert-message .alert-message___label {
    display: -webkit-flex;
    display: flex;
    -webkit-align-self: center;
    align-self: center;
  }
  
  .alert-message .alert-message___check {
    margin-right: 10px;
  }


  @media screen and (max-width: 500px) {
    .modal__content {
      width: 70vw;
    }
  }