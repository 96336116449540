.app-container {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: left center;
  height: 100vh;
  min-height: 600px;
  display: flex;
  flex-direction: column;
  position: relative;
}
